import { useState } from "react";
import {
  FaRegUserCircle,
  FaShoppingBag,
  FaSignInAlt,
  FaSignOutAlt,
  FaUser
} from "react-icons/fa";
import { FaShop } from "react-icons/fa6";
import digiticketLogo from "../assets/digiticketLogo.svg";
import { appName } from "../constants/enviroment";
import { useAppContext } from "../hooks/useAppContext";
import { Link } from "./router/Link";

// Translations.
import { useTranslation } from "react-i18next";

const ADMIN = "ROLE_ADMIN";
const ROLE_PUBLISHER_ADMIN = "ROLE_PUBLISHER_ADMIN";
const ROLE_PUBLISHER_APPROVAL = "ROLE_PUBLISHER_APPROVAL";
const ROLE_PUBLISHER_BOUNCER = "ROLE_PUBLISHER_BOUNCER";

export const Header = () => {
  const { user } = useAppContext();
  const { i18n, t } = useTranslation("global");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isTranslationsOpen, setIsTranslationsOpen] = useState(false);
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div>
      <div
        id="drawer-menu"
        className={`no-scrollbar opacity-90 w-full fixed top-[96px] left-0 h-screen shadow-md z-20 overflow-scroll transition-transform duration-500 transform uppercase ${
          isDrawerOpen
            ? "translate-x-0 opacity-100"
            : "-translate-x-full opacity-0"
        }`}
        onClick={() => setIsDrawerOpen(false)}
      >
        <div className="flex flex-col items-center bg-primary sm:w-[380px] h-screen p-4 overflow-auto overscroll-contain text-left text-secondary text-2xl">
          <div className="w-full flex flex-col gap-4 mb-4 mt-4 border-b-secondary">
            <Link to={`/`}>
              <div className="flex items-center">
                <FaShop />
                <p className="ml-2">{t("nav.buy-tickets")}</p>
              </div>
            </Link>
            <Link to={`/profile`}>
              <div className="flex items-center">
                <FaUser />
                <p className="ml-2">{t("nav.profile")}</p>
              </div>
            </Link>

            <Link to={`/orders`}>
              <div className="flex items-center">
                <FaShoppingBag />
                <p className="ml-2">{t("nav.my-orders")}</p>
              </div>
            </Link>
          </div>

          {user?.role === ROLE_PUBLISHER_ADMIN || user?.role === ADMIN ? (
            <div
              className="w-full flex flex-col gap-4 pt-4 mb-4 border-t-2 border-t-secondary"
              id="admin-and-publisher-options"
            >
              <Link to={`/users`}>{t("nav.see-users")}</Link>
              <Link to={`/publisher-events`}>{t("nav.publisher-events")}</Link>
              <Link to={`/publisher-orders`}>{t("nav.publisher-orders")}</Link>
              <Link to={`/register-publishers-approver`}>
                {t("nav.register-publishers-approver")}
              </Link>
              <Link to={`/register-publishers-bouncer`}>
                {t("nav.register-publishers-bouncer")}
              </Link>
            </div>
          ) : null}

          {user?.role === ADMIN ? (
            <div className="w-full flex flex-col gap-4 mb-4" id="admin-options">
              <Link to={`/publishers`}>{t("nav.see-publishers")}</Link>
              <Link to={`/register-publishers`}>
                {t("nav.register-publisher")}
              </Link>
              <Link to={`/register-user`}>{t("nav.register-user")}</Link>
            </div>
          ) : null}

          {user?.role === ROLE_PUBLISHER_ADMIN ||
          user?.role === ADMIN ||
          user?.role === ROLE_PUBLISHER_APPROVAL ? (
            <div
              className="w-full flex flex-col gap-4 mb-4"
              id="admin-and-publisher-options"
            >
              <Link to={`/publisher-orders-pending-approval`}>
                {t("nav.sales-pending-approval")}
              </Link>
            </div>
          ) : null}

          {user?.role === ROLE_PUBLISHER_ADMIN ||
          user?.role === ADMIN ||
          user?.role === ROLE_PUBLISHER_BOUNCER ? (
            <div
              className="w-full flex flex-col gap-4 mb-4"
              id="admin-and-publisher-options"
            >
              <Link to={`/qr-ticket-scan`}>{t("nav.scan-tickets")}</Link>
            </div>
          ) : null}

          <div className="w-full flex flex-col gap-4 mb-4">
            <Link to={`/login`} onSuccess={() => setIsDrawerOpen(false)}>
              <div className="flex items-center">
                <FaSignOutAlt />
                <p className="ml-2">{t("nav.logout")}</p>
              </div>
            </Link>
          </div>
        </div>
      </div>

      <header className="bg-primary p-4 w-full shadow-md fixed top-0 z-10">
        <div className="flex justify-between">
          <Link to={`/`}>
            <div className="flex flex-column items-center">
              <img
                src={digiticketLogo}
                alt="digiticket logo"
                className="w-12 h-12 mr-2"
              />
              <h1 className="text-xl text-secondary font-bold">{appName}</h1>
            </div>
          </Link>

          <div className="flex-grow"></div>
          {user === null ? (
            <div className="flex gap-6 items-center">
              {/*<div className="h-full flex flex-col  gap-2 relative w-[32px]">
                <button
                  className="text-secondary flex justify-center w-full"
                  onClick={() => setIsTranslationsOpen(!isTranslationsOpen)}
                >
                  <BsTranslate />
                </button>
                {isTranslationsOpen && (
                  <div className="text-primary flex flex-col bg-tertiary w-full absolute top-4">
                    <button
                      className={`${i18n.language === "en" ? "bg-secondary" : ""} w-full px-2`}
                      onClick={() => changeLanguage("en")}
                    >
                      EN
                    </button>
                    <button
                      className={`${i18n.language === "es" ? "bg-secondary" : ""} w-full px-2`}
                      onClick={() => changeLanguage("es")}
                    >
                      ES
                    </button>
                  </div>
                )}
              </div>*/}
              <div className="flex flex-col text-secondary lora-regular mr-2">
                <Link to={`/login`} onSuccess={() => setIsDrawerOpen(false)}>
                  <div className="flex items-center">
                    <FaSignInAlt />
                    <p className="ml-2">{t("nav.login")}</p>
                  </div>
                </Link>
              </div>
            </div>
          ) : null}
          {user !== null ? (
            <div className="flex items-center gap-6">
              {/*<div className="h-full flex flex-col  gap-2 relative w-[32px]">
                <button
                  className="text-secondary flex justify-center w-full"
                  onClick={() => setIsTranslationsOpen(!isTranslationsOpen)}
                >
                  <BsTranslate />
                </button>
                {isTranslationsOpen && (
                  <div className="text-primary flex flex-col bg-tertiary w-full absolute top-5">
                    <button
                      className={`${i18n.language === "en" ? "bg-secondary" : ""} w-full px-2`}
                      onClick={() => changeLanguage("en")}
                    >
                      EN
                    </button>
                    <button
                      className={`${i18n.language === "es" ? "bg-secondary" : ""} w-full px-2`}
                      onClick={() => changeLanguage("es")}
                    >
                      ES
                    </button>
                  </div>
                )}
              </div>*/}
              <div
                id="drawer-menu-button"
                className="flex flex-col hover:cursor-pointer"
                onClick={() => setIsDrawerOpen(!isDrawerOpen)}
              >
                <div>
                  <div className="m-auto">
                    <FaRegUserCircle
                      className="text-secondary"
                      style={{ width: "40px", height: "40px" }}
                    />
                  </div>
                  <p className="text-secondary uppercase text-center lora-bold">
                    {user.firstName && user.lastName
                      ? `${user.firstName.charAt(0)} ${user.lastName.charAt(0)}`
                      : user.email.charAt(1)
                        ? `${user.email.charAt(0)} ${user.email.charAt(1)}`
                        : ""}
                  </p>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </header>

      <div id="header-fixed-position-filler" className="h-[96px]">
        {/* Filler for fixed header position h-[96px]*/}
      </div>
    </div>
  );
};
