import { Layout } from "../components/Layout";

export default function ContactUs() {
  return (
    <Layout>
      <div id="contact-us" className="flex flex-col  bg-tertiary">
        <h1 id="contact-us" className="lora-bold my-4 text-2xl">
          Informacion de Contacto
        </h1>
        <div className="w-full border border-primary mb-4"></div>
        <section className="text-primary lora-regular text-lg mb-4">
          <h2 className="text-xl mb-4">Digitickets® Costa Rica</h2>
          <p>Whatsapp: </p>
          <a href="https://wa.me/50664032010" className="underline lora-bold mb-4">+50664032010</a>
          <p>Correo: </p>
          <a href="mailto:info@digiticket.shop" className="underline lora-bold mb-4">info@digiticket.shop</a>
        </section>
        <div className="w-full border border-primary mb-4"></div>
        <section className="text-primary mb-4 text-lg">
          <h2 className="text-xl mb-4">StoreFront®/StoreHQ®</h2>
          <p>Correo:</p>
          <a href="mailto:info@mistorefront.com" className="underline lora-bold">info@mistorefront.com</a>
        </section>
      </div>
    </Layout>
  );
}