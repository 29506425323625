import { useState } from "react";
import { FaTrash } from "react-icons/fa";
import { MdClose, MdDateRange, MdEdit, MdPlace, MdTimer } from "react-icons/md";
import { useAppContext } from "../hooks/useAppContext";
import { EventData } from "../types/EventDataTypes";
import { Link } from "./router/Link";

const ADMIN = "ROLE_ADMIN";
const ROLE_PUBLISHER_ADMIN = "ROLE_PUBLISHER_ADMIN";

const EventCard = ({
  event,
  onEditTier,
  onEditEvent,
  onAddTier,
  onDeleteEvent
}: {
  event: EventData;
  onEditEvent?: () => void;
  onEditTier?: () => void;
  onAddTier?: () => void;
  onDeleteEvent?: () => void;
}) => {
  const { user } = useAppContext();
  const [isHovered, setIsHovered] = useState<boolean>(false);
  // Función para obtener la fecha formateada
  const getDate = (date: string) => {
    const eventDate = new Date(date);
    const day = eventDate.getDate().toString().padStart(2, "0");
    const month = (eventDate.getMonth() + 1).toString().padStart(2, "0"); // Los meses van de 0 a 11
    const year = eventDate.getFullYear();

    return `${day}/${month}/${year}`;
  };

  // Función para obtener la hora formateada
  const getHour = (date: string) => {
    const eventDate = new Date(date);
    let hours = eventDate.getHours();
    const minutes = eventDate.getMinutes().toString().padStart(2, "0");
    const period = hours < 12 ? "AM" : "PM";

    if (hours === 0) {
      hours = 12; // Caso de medianoche
    } else if (hours > 12) {
      hours -= 12; // Convertir al formato de 12 horas
    }

    const formattedHours = hours.toString().padStart(2, "0");

    return `${formattedHours}:${minutes} ${period}`;
  };

  // Obtener la fecha y la hora formateadas
  const date = getDate(event.eventDate);
  const hour = getHour(event.eventDate);

  const canEdit =
    (user?.role === ADMIN || user?.role === ROLE_PUBLISHER_ADMIN) &&
    onEditEvent &&
    onEditTier;

  return (
    <div
      id="event-card"
      className={`m-2 bg-primary drop-shadow-lg ${canEdit ? "border-4 border-secondary " : ""}`}
    >
      {canEdit ? (
        <div className="absolute top-2 w-full px-2 flex justify-between">
          <div
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <div className="bg-secondary text-primary p-2 hover:bg-opacity-90 transition-all duration-300 ease-in-out">
              {isHovered ? (
                <div className="flex justify-end">
                  <button onClick={() => setIsHovered(false)}>
                    <MdClose />
                  </button>
                </div>
              ) : (
                <MdEdit />
              )}
            </div>
            {isHovered && (
              <div className="flex flex-col mt-2 space-y-2">
                <button
                  className="bg-secondary text-primary  p-2 hover:bg-opacity-90"
                  onClick={onEditEvent}
                >
                  Editar Evento
                </button>
                <button
                  className="bg-secondary text-primary  p-2 hover:bg-opacity-90 disabled:hidden"
                  disabled={event.eventTiers.length === 0}
                  onClick={onEditTier}
                >
                  {event.eventTiers.length === 0
                    ? "Este evento aun no tiene Localidades"
                    : "Editar Localidades"}
                </button>
                <button
                  className="bg-secondary text-primary  p-2 hover:bg-opacity-90"
                  onClick={onAddTier}
                >
                  Agregar Localidades
                </button>
              </div>
            )}
          </div>
          <button
            className="bg-secondary text-primary p-2 hover:bg-opacity-90 absolute right-2"
            onClick={onDeleteEvent}
          >
            <FaTrash className="cursor-pointer " />
          </button>
        </div>
      ) : null}
      <div id="event-image-wrapper">
        <img
          id="event-image"
          className="w-[350px] h-[350px]"
          src={event.imageUrl}
          alt={event.name}
        />
      </div>
      <div id="event-info" className="py-2 px-4 lora-regular">
        <h2 className="text-xl text-secondary uppercase truncate animate-text max-w-[310px]">
          {event.name}
        </h2>
        <p className="text-tertiary text-sm flex items-center gap-2">
          {<MdPlace />} {event.location}
        </p>
        <p className="text-tertiary text-sm flex items-center gap-2">
          {<MdDateRange />} {date}
        </p>
        <p className=" text-tertiary text-sm flex items-center gap-2">
          {<MdTimer />} {hour}
        </p>
      </div>
      <div className="w-full px-4 pb-4">
        {!canEdit ? (
          <Link to={`/event/?name=${event.customUrl}`}>
          <div className="bg-secondary  mt-2 p-2 hover:bg-opacity-80">
            <p className="text-primary uppercase text-center lora-regular text-lg">
              Ver mas
            </p>
          </div>
        </Link>
        ) : (
          <Link to={`/event-publisher-details/?eventId=${event.id}`}>
            <div className="bg-secondary  mt-2 p-2 hover:bg-opacity-80">
              <p className="text-primary uppercase text-center lora-regular text-lg">
                Ver más
              </p>
            </div>
          </Link>
        )}
      </div>
    </div>
  );
};

export default EventCard;
